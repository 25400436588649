import React, { useState, useContext } from 'react';
import { format } from 'date-fns';

import AppBody from '../components/AppBody';
import Button from '../components/Button';
import SubHeading from '../components/text/SubHeading';

import EpisodeContext from '../context/episodeContext';
import '../styles/Book.css';

const selectOptions = [
  'nsw',
  'vic',
  'act',
  'qld',
  'sa',
  'nt',
  'wa',
  'tas',
]

const Book = () => {
  const context = useContext(EpisodeContext)
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [eventName, setEventName] = useState('');
  const [date, setDate] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [cause, setCause] = useState('');
  const [message, setMessage] = useState('');
  const [missingEmail, setMissingEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [fail, setFail] = useState(false)
  const [loading, setLoading] = useState(false)

  const submitForm = async () => {
    if (!email || !email.match(/^\S+@\S+\.\S+$/)) { return setMissingEmail(true) }

    const data = {
      name: name,
      email: email,
      eventName: eventName,
      date: date,
      city: city,
      state: state,
      cause: cause,
      message: message,
    }

    const params = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }

    try {
      setFail(false)
      setLoading(true)
      const resp = await fetch(`${context.url}/messages/create`, params).then(r => r.json());
      setLoading(false)
      if (resp.message === 'ok') { return setSuccess(true) }
      
      // eslint-disable-next-line 
      throw 'Something went wrong'
    } catch (error) {
      setLoading(false)
      setFail(true)
    }
  }

  return (
    <AppBody metaKey={'book'}>
      <SubHeading text={'BOOK A TALK'} />

      <div className={'book-a-talk-preamble'}>
        <p>
          Passion and Perspective host Jonathan Robinson-Lees is committed to inspiring the community. Jonathan challenges individuals to embrace their passion and shift their mindset through a powerful and meaningful talk, delivered to corporates, schools, universities and community groups.
      </p>
      </div>
      
      {success ? (
        <div className={'book-a-talk-success'}>
          {'THANK YOU FOR YOUR ENQUIRY. WE WILL BE IN TOUCH SHORTLY.'}
        </div>
      ) : (
        <div className={'book-a-talk-section'}>
          <div>
            {fail && (
              <div>
                <p>
                  {'Whoops. Something went wrong. Try again, or email jonathan@passionandperspective.com.au'}
                </p>
              </div>
            )}
          </div>

          <div className={'book-a-talk-form-wrapper'}>
            <div className={'book-a-talk-form-item-wrapper'}>
              <input
                type={'text'}
                name={'name'}
                placeholder={'NAME'}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className={'book-a-talk-form-item-wrapper'}>
              {missingEmail && <div className={'required-field-text'}>{'Email must be in correct format.'}</div>}

              <input
                type={'email'}
                className={missingEmail ? 'required-field' : ''}
                name={'email'}
                placeholder={'EMAIL'}
                value={email}
                onChange={(e) => {
                  if (missingEmail) { setMissingEmail(false) }
                  setEmail(e.target.value)
                }}
              />
            </div>

            <div className={'book-a-talk-form-item-wrapper'}>
              <input
                type={'text'}
                name={'eventName'}
                placeholder={'EVENT NAME'}
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
            </div>

            <div className={'book-a-talk-form-item-wrapper'}>
              <input
                type={'text'}
                onFocus={(e) => e.target.type = 'date'}
                onBlur={(e) => e.target.type = 'text'}
                id={'date'}
                name={'date'}
                placeholder={'DATE OF EVENT'}
                min={format(new Date(), 'yyyy-MM-dd')}
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div className={'book-a-talk-form-item-wrapper'}>
              <input
                type={'text'}
                name={'city'}
                placeholder={'CITY'}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div className={'book-a-talk-form-item-wrapper'}>
              <select onChange={(e) => setState({ state: e.target.value })} name={'state'} id={'state'}>
                <option>{'STATE'}</option>
                {selectOptions.map((o, i) => (
                  <option value={o} key={`select${i}`}>
                    {o.toLocaleUpperCase()}
                  </option>
                ))}
              </select>
            </div>

            <div className={'book-a-talk-form-item-wrapper  book-a-talk-form-item-wrapper-full-width'}>
              <input
                type={'text'}
                name={'cause'}
                placeholder={'CAUSE / PURPOSE'}
                value={cause}
                onChange={(e) => setCause(e.target.value)}
              />
            </div>


            <div className={'book-a-talk-form-item-wrapper book-a-talk-form-item-wrapper-full-width'}>
              <textarea
                name={'message'}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={'ENTER MORE DETAILS IF REQUIRED'}
                id={'message'}>
                </textarea>
            </div>

            <div className={'book-a-talk-form-item-wrapper book-a-talk-form-item-wrapper-full-width book-a-talk-form-bottom'}>
              <Button
                inverse={true}
                text={loading ? 'SUBMITTING ... ' : 'SUBMIT'}
                onClick={submitForm}
              />
            </div>
          </div>

          <div className={'book-a-talk-spacer'}></div>
        </div>
      )}
    </AppBody>
  )
};

export default Book;
