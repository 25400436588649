import { useEffect, useState } from 'react';

import AppBody from '../components/AppBody';
import Episodes from '../components/Episodes';
import MailChimpForm from '../components/MailChimpForm';

const Episode = () => {
  const [showPopup, setShowPopup] = useState();

  useEffect(() => {
    const visitedBefore = localStorage.getItem('hasVisited');
    if (!visitedBefore) {
      setTimeout(() => setShowPopup(true), 2000)
      localStorage.setItem('hasVisited', true)
    }
  }, [])
  return (
    <AppBody metaKey={'listen'}>
      <Episodes title={'ALL EPISODES'} />

      {showPopup && (
        <div className={'popup-wrapper'}>
          <div className={'popup'}>
            <MailChimpForm onClose={() => setShowPopup(false)} />
          </div>
        </div>
      )}
    </AppBody>
  )
};

export default Episode;
