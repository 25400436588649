import React from 'react';

import AppBody from '../components/AppBody';
import SubHeading from '../components/text/SubHeading';

import FacebookLogo from '../images/facebook-black-64-64.png';
import InstagramLogo from '../images/instagram-black-64-64.png';
import '../styles/Contact.css';

const Contact = () => {
  return (
    <AppBody metaKey={'contact'}>
      <SubHeading text={'CONTACT'} />

      <div className={'contact-section'}>
        <h2>{'WE WOULD LOVE TO HEAR FROM YOU'}</h2>
        <h2>{'FOR MORE INFORMATION OR TO REACH OUT PLEASE CONTACT:'}</h2>

        <div className={'details-wrapper'}>
          <h2>{'JONATHAN ROBINSON-LEES'}</h2>
          <h2>{'FOUNDER AND HOST'}</h2>
          <h2>{'JONATHAN@PASSIONANDPERSPECTIVE.COM.AU'}</h2>
          <h2>{'+61 487 206 155'}</h2>
        </div>

        <div className={'socials-icons'}>
          <a
            href={'https://www.facebook.com/passionperspectivepodcast'}
            target={'_blank'}
            rel={'noreferrer'}
            className={'fb'}>
            <img src={FacebookLogo} alt={'Facebook Logo'} />
          </a>
          <a
            href={'https://www.instagram.com/passionperspectivepodcast'}
            target={'_blank'}
            rel={'noreferrer'}
            className={'insta'}>
            <img src={InstagramLogo} alt={'Facebook Logo'} />
          </a>
        </div>
      </div>

    </AppBody>
  )
};

export default Contact;
