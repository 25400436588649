import React from 'react';
import MetaTags from 'react-meta-tags';

import Footer from './Footer';
import { metaTags } from '../functions/pageMetaData';
import '../styles/AppBody.css';

const renderMetaTags = (key, variable) => {
  const v = metaTags(key, variable) || {}

  return (
    <MetaTags>
      <title>{v.pageTitle || 'Passion and Perspective'}</title>
      <meta name={'description'} content={v.pageDescription || 'Keep up to date with the latest from the Passion and Perspective Podcast!'} />
      <meta property={'og:title'} content={v.pageHeading || 'Passion and Perspective Podcast'} />
      <meta property={'og:image'} content={v.pageImage || 'https://passionandperspective.com.au/static/media/logo.437a749a0252a318253b.png'} />
    </MetaTags>
  )
};

const AppBody = ({
  children,
  metaKey,
  variable,
}) => {
  return (
    <div className={'app'}>
      {renderMetaTags(metaKey, variable)}
      
      <div className={'appBody wrap'}>
        {children}
      </div>

      <Footer />
    </div>
  )
};

export default AppBody;
