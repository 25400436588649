import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import AppBody from '../components/AppBody';
import Episodes from '../components/Episodes';

import EpisodeContext from '../context/episodeContext';
import { stripHTMLTags } from '../functions/textHelpers';
import '../styles/Episode.css';

import SpotifyLogo from '../images/spotify_logo.png';
import AnchorLogo from '../images/anchor_logo.png';
import AppleLogo from '../images/apple_logo.png';
import FacebookLogo from '../images/facebook_logo.png';
// import InstagramLogo from '../images/instagram_logo.png';
import DownArrow from '../images/down_arrow_white.png';
import UpArrow from '../images/up_arrow_white.png';

const CLIENT_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_CLIENT_URL : process.env.REACT_APP_PROD_CLIENT_URL;

const listenIcons = [{
  text: 'Spotify',
  value: 'spotify_link',
  backup: process.env.REACT_APP_SPOTIFY_LINK,
  icon: SpotifyLogo,
}, {
  text: 'Anchor',
  value: 'link',
  icon: AnchorLogo,
}, {
  text: 'Apple',
  value: 'apple_link',
  backup: process.env.REACT_APP_APPLE_LINK,
  icon: AppleLogo,
}];
const shareItIcons= [{
  text: 'Facebook',
  href: `${process.env.REACT_APP_FB_SHARE_LINK}${CLIENT_URL}listen/`,
  icon: FacebookLogo,
}, 
// {
//   text: 'Instagram',
//   href: process.env.REACT_APP_INSTAGRAM_SHARE_LINK,
//   icon: InstagramLogo,
// }
];

const Episode = () => {
  const params = useParams();
  const context = useContext(EpisodeContext);
  const [episode, setEpisode] = useState(undefined);
  const [paragraphs, setParagraphs] = useState(undefined);
  const [firstParagraph, setFirstParagraph] = useState(undefined);
  const [expand, setExpand] = useState(false);
  const [initalRenderComplete, setInitalRenderComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noEpisode, setNoEpisode] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (initalRenderComplete) {
      setLoading(true)
      setExpand(false)
    }
    setInitalRenderComplete(true);
    setNoEpisode(false);

    try {
      const tempEp = context.episodes.find(e => e.url_slug === params.slug);
      const tempParagrahs = [];

      const noClosingTags =  tempEp.description.replaceAll('\n', '').replaceAll('</p>', '');
      const array = [...noClosingTags.matchAll(/<p>/g)];
      for (let i = 0; i < array.length; i++) {
        const endIndex = i === array.length - 1 ? (noClosingTags.length - 3 - array[i].index) : (array[i + 1].index - 3 - array[i].index);

        if (i === 0) { setFirstParagraph(noClosingTags.substr(array[i].index + 3, endIndex)) }

        tempParagrahs.push(noClosingTags.substr(array[i].index + 3, endIndex));
      }

      setParagraphs(tempParagrahs)
      setEpisode(tempEp);
      const timeout = setTimeout(() => setLoading(false), 2000)

      return () => {
        clearTimeout(timeout)
      }
    } catch (error) {
      console.log('pages/Episode.jsx::useEffect() Error - ', error);
      setLoading(false)
      setNoEpisode(true);
    }
  }, [params.slug, context.episodes, initalRenderComplete]);

  const handleExpandClick = () => {
    if (expand) {
      const ele = document.querySelector('.latestEpWrapper');
      ele.scrollIntoView();
    }

    setExpand(!expand);
  };

  const renderIcons = (icons, type) => (
    icons.map((i, index) => (
      <a
        key={`${type}${index}`}
        className={'iconWrapper'}
        href={i.href ? (`${i.href}${episode.url_slug}`) : (episode[i.value] || i.backup)}
        rel={'noreferrer'}
        target={'_blank'}>
        <img
          src={i.icon}
          className={`thirdPartyLogo ${index === 0 ? 'firstLogo' : ''}`}
          alt={`${i.text} Logo`}
        />
      </a>
    ))
  );

  return (
    <AppBody metaKey={'episode'} variable={episode}>
      {episode ? (
        <div className={`content episode-content ${(loading && initalRenderComplete) ? 'article-loading' : ''}`}>
          <div className={'innerContent'}>
            <div className={`ppBGBox ${expand ? 'fullHeight' : ''}`} />

            <div className={'contentBody'}>
              <img src={episode.s3_image_link} alt={'Latest podcast guest'} className={'latestEpImage imageRounded smallerImg'} />

              <div className={'latestEpWrapper'}>
                <div className={'latest-ep-bg'}></div>
                <h2 className={'latestEpText heading'}>
                  {episode.title.toUpperCase()}
                </h2>
                <h4 className={'latestEpText'}>
                  {format(new Date(episode.publish_date), 'do MMMM, yyyy')}
                </h4>

                <div className={'iconRow'}>
                  <div className={'iconRowItem'}>
                    <h5 className={'latestEpText'}>{'Where to Listen'}</h5>
                    {renderIcons(listenIcons, 'listenIcon')}
                  </div>

                  <div className={'iconRowItem'}>
                    <h5 className={'latestEpText'}>{'Share it'}</h5>
                    {renderIcons(shareItIcons, 'shareItIcons')}
                  </div>
                </div>

                {expand ? (
                  paragraphs && paragraphs.map((p, i) => (
                    <p key={`description${i}`} className={'latestEpText latestEpTextParagraph'}>
                      {stripHTMLTags(p).map((item, index) => (
                        item.em ? (
                          <em key={`em${i}${index}`}>{item.text}</em>
                        ) : item.strong ? (
                          <strong key={`strong${i}${index}`}>{item.text}</strong>
                        ) : (
                          <span key={`span${i}${index}`}>{item.text}</span>
                        )
                      ))}
                    </p>
                  ))
                ) : (
                  firstParagraph && (
                    <>
                      <p className={'latestEpText latestEpTextParagraph'}>
                        {stripHTMLTags(firstParagraph).map((item, i) => (
                          item.em ? (
                            <em key={`em${i}`}>{item.text}</em>
                          ) : (
                            <span key={`span${i}`}>{item.text}</span>
                          )
                        ))}
                      </p>
                    </>
                  )
                )}
                
                {(paragraphs && paragraphs.length <= 1) ? null : (
                  <div className={'expand-arrow-wrapper'} onClick={handleExpandClick}>
                    <img
                      src={expand ? UpArrow : DownArrow}
                      className={`arrow ${expand ? '' : 'arrowPaddingBottom'}`}
                      alt={`${expand ? 'Up' : 'Down'} Arrow to ${expand ? 'contract' : 'expand'} text`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : noEpisode ? (
        <div className={'placeholderDiv'} >
          <p>No episode found. Try clicking another link.</p>
        </div>
      ) : (
        <div className={'placeholderDiv'} />
      )}

      <Episodes title={'Other Episodes'} currentSelectedEpID={episode && episode.id} />
    </AppBody>
  )
};

export default Episode;
