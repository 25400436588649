import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/Card.css';

const Card = ({ episode, last }) => {
  const style = {
    color: episode.title_text_colour.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/) ? episode.title_text_colour : '#000000',
  }
  const splitTitle = episode.title.split(' - ')

  return (
    <>
      <div className='card-content'>
        {splitTitle.map((p, i) => (
          <p key={`title${p}${i}`} className={'mediumHeading'} style={style}>
            {p.toUpperCase()}
          </p>
        ))}
      </div>

      <Link
        to={`/listen/${episode.url_slug}`}
        style={{  marginRight: last ? 0 : '20px' }}
        className={'card'}>
        <img className={'episodeImage'} src={episode.s3_image_link} alt={`Episode: ${episode.title}`} />
      </Link>
    </>
  )
};

export default Card;
