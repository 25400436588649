import React, { useEffect, useState, useContext } from 'react';

import AppBody from '../components/AppBody';
import SubHeading from '../components/text/SubHeading';

import EpisodeContext from '../context/episodeContext';
import '../styles/About.css';

const About = () => {
  const context = useContext(EpisodeContext)

  const [aboutContent, setAboutContent] = useState(undefined);

  useEffect(() => {
    (async () => {
      const resp = await fetch(`${context.url}/about`).then(r => r.json());
      setAboutContent(resp);
    })()
  }, [context.url]);

  return (
    <AppBody metaKey={'about'}>
      {aboutContent && aboutContent.length > 0 && aboutContent.map((data, i) => (
        <React.Fragment key={`about${i}`}>
          <SubHeading text={data.title} />
            <div className={'about-text-wrapper'}>
              <p>
                {data.content.split('\n').map((word, index) => {
                  if (!word || word === '\r') { return <br key={`${index}word`} /> }

                  return <span key={`${index}word`}>{word}<br/></span>
                })}
              </p>
            </div>
        </React.Fragment>
      ))}
    </AppBody>
  )
};

export default About;
