import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import AppBody from '../components/AppBody';
import LatestEpisode from '../components/LatestEpisode';
import CardWrapper from '../components/CardWrapper';
import ArticleWrapper from '../components/ArticleWrapper';
import Button from '../components/Button';

import EpisodeContext from '../context/episodeContext';
import '../styles/Home.css';

const Home = () => {
  const context = useContext(EpisodeContext)

  const [latestEpisode, setLatestEpisode] = useState(undefined);
  const [recentEpisodes, setRecentEpisodes] = useState(undefined);
  const [mostPopularEpisodes, setMostPopularEpisodes] = useState(undefined);
  const [otherEpisodes, setOtherEpisodes] = useState(undefined);
  const [articles, setArticles] = useState(undefined);

  const episodeLimit = context.isTablet ? 6 : 4;

  useEffect(() => {
    if (context.episodes[0] && context.episodes[0].s3_image_link) { setLatestEpisode(context.episodes[0]) }
    else if (context.episodes[1] && context.episodes[1].s3_image_link) { setLatestEpisode(context.episodes[1]) }

    const offset = context.episodes[0] && context.episodes[0].s3_image_link ? 1 : 2;
    const recentEps = context.episodes.slice(offset, 6 + offset);

    const otherEps = [];

    if (!otherEpisodes) {
      for (let i = 1; i <= 6; i++) {
        let inArr = true;
        while (inArr) {
          const num = Math.floor(Math.random() * (context.episodes.length - (episodeLimit))) + (episodeLimit);
          const temp = context.episodes[num];
          let match = false

          if (!temp.most_popular_rank && temp.s3_image_link)  {
            for (let e of otherEps) {
              if (e.link === temp.link) { match = true }
            }

            if (!match) {
              otherEps.push(temp);
              inArr = false;
            }
          }
        }
      }

      setOtherEpisodes(otherEps);
    }
    
    setRecentEpisodes(recentEps.slice(0, episodeLimit))
    setArticles(context.articles && context.articles.slice(0, episodeLimit))
    setMostPopularEpisodes(context.episodes.filter(e => e.most_popular_rank).sort((a, b) => a.most_popular_rank - b.most_popular_rank).slice(0, episodeLimit))
  }, [episodeLimit, context.episodes, context.articles, otherEpisodes]);

  return (
    <AppBody>
      {latestEpisode && <LatestEpisode episode={latestEpisode} />}

      <CardWrapper type={'latest'} subHeadingText={'RECENT EPISODES'} episodes={recentEpisodes} />
      
      <ArticleWrapper subHeadingText={'LATEST ARTICLES'} articles={articles} />

      <CardWrapper type={'popular'} subHeadingText={'MOST POPULAR EPISODES'} episodes={mostPopularEpisodes} />

      <CardWrapper type={'other'} subHeadingText={'OTHER EPISODES'} episodes={otherEpisodes && otherEpisodes.slice(0, episodeLimit)} />

      <Link to={'/listen'} className={'see-all-episodes'}>
        <Button text={'SEE ALL EPISODES'} inverse />
      </Link>
    </AppBody>
  )
};

export default Home;
