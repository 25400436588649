import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import SubHeading from './text/SubHeading';

const ArticleWrapper = ({ subHeadingText, articles }) => {
  return (
    <>
      <SubHeading text={subHeadingText} />

      <div className={'cardWrapper'}>
        {articles && articles.map((article, index) => (
          <Link key={`article${index}`} to={`/articles/${article.url_slug}`} className={'individualCard'}>
            <div className={'article-image-wrapper'}>
              <img className={'article-image'} src={article.image_link} alt={`Article: ${article.title}`} />
            </div>
            <p>
              {format(new Date(article.publish_date), 'do MMMM, yyyy')}
            </p>
            <p className={'heading'}>
              {article.title}
            </p>
            <p>
              {article.sub_heading}
            </p>
          </Link>
        ))}
      </div>
    </>
  )
};

export default ArticleWrapper;
