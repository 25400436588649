import React, { useEffect, useState, useContext, useMemo }from 'react';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import EpisodeContext from '../context/episodeContext';
import '../styles/Articles.css';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Articles = ({ keywords }) => {
  const context = useContext(EpisodeContext)
  const query = useQuery()
  const [articles, setArticles] = useState(undefined);
  const [initalRenderComplete, setInitalRenderComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initalRenderComplete) { setLoading(true) }
    setInitalRenderComplete(true);

    (async () => {
      try {
        if (query && query.get('keyword')) {
          const match = keywords && keywords.find((k) => k.word === query.get('keyword'))
          if (match) {
            const resp = await fetch(`${context.url}/articles/keywords/${match.id}`).then(r => r.json());
            setArticles(resp);
          }
        } else {
          setArticles(context.articles);
        }

        setLoading(false)
      } catch (error) {
        console.log('Articles.jsx::useEffect() Error - ', error);
        setLoading(false);
      }
    })()
  }, [query, context.url, context.articles, initalRenderComplete, keywords]);

  return (
    <div className={'verticalSections articles-content'}>
      <div className={'articles-wrapper'}>
        {!loading && articles && articles.length > 0 ? (
          articles.map((article, index) => (
            <div key={`article${index}`} className={'article-loading'}>
              <Link to={`/articles/${article.url_slug}`} className={'article'}>
                <div>
                  <p className={'mediumHeading'}>
                    {article.title}
                  </p>
                  <p className={'article-sub-heading'}>
                    {article.sub_heading}
                  </p>
                  <p className={'article-date'}>
                    {format(new Date(article.publish_date), 'do MMMM, yyyy')}
                  </p>
                </div>
                <div>
                  {article.image_link ? (
                    <img className={'article-image'} src={article.image_link} alt={`Article: ${article.title}`} />
                  ) : (
                    <div className={'article-image'} />
                  )}
                </div>
              </Link>
            </div>
          ))
        ) : !loading ? (
          <h4 className='noSearchResults'>
            {'No Articles Available'}
          </h4>
        ) : (
          <></>
        )}
      </div>

      <div className={'discover'}>
        <div className={'discover-wrapper'}>
          <p className={'mediumHeading'}>
            {'Discover more great articles'}
          </p>

          <div className={'keywords-wrapper'}>
            {keywords && keywords.map((data, i) => {
              const selected = query && query.get('keyword') && query.get('keyword') === data.word
              
              return (
                <Link
                  className={`keyword-link ${selected ? 'selected-keyword' : ''}`}
                  key={`keyword${i}`}
                  to={selected ? '/articles' : `/articles?keyword=${data.word}`}>
                  <div className={'keyword'}>
                    {data.word}
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Articles;
