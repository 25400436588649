import React from 'react';

import '../../styles/SubHeading.css';

const SubHeading = ({ text, wrapperStyle }) => {
  return (
    <div className={'subHeadingWrapper'} stle={wrapperStyle}>
      <p className={'subHeadingText heading'}>
        {text}
      </p>
    </div>
  )
};

export default SubHeading;
