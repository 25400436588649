import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

import EpisodeContext from '../context/episodeContext';
import TopNavLink from './TopNavLink';

import logo from '../images/logo.png';
import '../styles/TopNav.css';
import '../styles/BurgerMenu.css';
import Button from './Button';

const linkDetails = [{
  to: '/listen',
  text: 'LISTEN',
}, {
  to: '/articles',
  text: 'ARTICLES',
}, {
  to: '/about',
  text: 'ABOUT',
}, {
  to: '/contact',
  text: 'CONTACT',
}];

const TopNav = () => {
  const context = useContext(EpisodeContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation()

  return (
    <header className={`wrap ${context.isMobile ? 'mobile-header-padding' : 'padding'}`}>
      <div className={'navWrapper'}>
        <Link className={`image ${context.isMobile ? 'imageMobile' : ''}`} to={'/'}>
          <img src={logo} alt={'Logo'} className={'image'} />
        </Link>

        {context.isMobile ? (
          <Menu isOpen={menuOpen} onStateChange={(e) => setMenuOpen(e.isOpen)} right width={'100%'}>
            {linkDetails.map((link) => (
              <Link onClick={() => setMenuOpen(false)} key={link.to} to={link.to}>
                {link.text}
              </Link>
            ))}
            <Link onClick={() => setMenuOpen(false)} to={'/book-a-talk'}>
              {'BOOK A TALK'}
            </Link>
          </Menu>
        ) : (
          <div className={'linksWrapper'}>
            {linkDetails.map(link => (
              <TopNavLink
                key={link.to}
                to={link.to}
                linkText={link.text}
                currentLink={location.pathname}
              />
            ))}
            <Link to={'/book-a-talk'}>
              <Button
                style={{
                  padding: '15px 10px',
                  marginLeft: '15px',
                  border: '1px black solid',
                }}
                text={'BOOK A TALK'}
              />
            </Link>
          </div>
        )}

      </div>
    </header>
  )
};

export default TopNav;
