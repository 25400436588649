const getRandom = (arr, n) => {
  let num = arr.length < n ? arr.length : n
  var result = new Array(num),
      len = arr.length,
      taken = new Array(len);
  if (num > len)
      throw new RangeError("getRandom: more elements taken than available");
  while (num--) {
      var x = Math.floor(Math.random() * len);
      result[num] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export {
  getRandom,
}
