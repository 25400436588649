import React from 'react';
import { Link } from 'react-router-dom';

const TopNavLink = ({ linkText, to, currentLink }) => {
  const styles = currentLink && currentLink.match(linkText.toLocaleLowerCase()) ? { borderBottom: '1px grey solid' } : {}

  return (
    <Link
      to={to}
      style={{
        textDecoration: 'none',
        color: 'black',
        fontSize: '1.5rem',
        ...styles,
      }}
      className={'topNavLink heading'}>
      {linkText}
    </Link>
  )
}

export default TopNavLink;
