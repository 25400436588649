import React, { useEffect, useState, useContext }from 'react';

import Articles from '../components/Articles';
import SubHeading from '../components//text/SubHeading';
import AppBody from '../components/AppBody';
import EpisodeContext from '../context/episodeContext';

const ArticlesParents = () => {
  const context = useContext(EpisodeContext)
  const [keywords, setKeywords] = useState(undefined);

  useEffect(() => {
    (async () => {
      const getKeyWords = await fetch(`${context.url}/keywords`).then(r => r.json());
      setKeywords(getKeyWords || [])
    })()
  }, [context.url]);

  return (
    <AppBody metaKey={'articles'}>
      <SubHeading text={'ALL ARTICLES'} />

      {keywords ? <Articles keywords={keywords} /> : <></>}

    </AppBody>
  )
};

export default ArticlesParents;
