import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import Button from './Button';

const LatestEpisode = ({ episode }) => {
  return (
    <div className={'content home-latest-episode'}>
      <div className={'innerContent'}>
        <div className={'ppBGBox'} />

        <div className={'contentBody'}>
          <Link className={'latest-ep-img-wrapper'} to={`/listen/${episode.url_slug}`}>
            <img src={episode.s3_image_link} alt={'Latest podcast guest'} className={'latestEpImage imageRounded'} />
          </Link>

          <div className={'latestEpWrapper'}>
            <h2 className={'latestEpText largeHeading'}>
              {'LATEST EPISODE'}
            </h2>
            <h2 className={'latestEpText heading'}>
              {episode.title.toUpperCase()}
            </h2>
            <h4 className={'latestEpText'}>
              {format(new Date(episode.publish_date), 'do MMMM, yyyy').toUpperCase()}
            </h4>
            <Link className={'view-episode-button'} to={`/listen/${episode.url_slug}`}>
              <Button text={'VIEW EPISODE'} style={{ marginTop: '10px' }} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default LatestEpisode;
