// Currently only set up to strip <em> tag. Can be adjusted if other tags pop up!

const stripHTMLTags = (p) => {
  const arr = [];
  let emExists = true;
  let index = 0;

  while (emExists) {
    const opt = {}
    opt.em = p.substr(index).match('<em>')
    opt.strong = p.substr(index).match('<strong>')
    if (opt.em || opt.strong) {
      const value = (!opt.em || (opt.strong && opt.em.index > (opt.strong && opt.strong.index))) ? 'strong' : 'em'

      const endIndex = p.substr(index).match(`</${value}>`);
      const lengthOfTag = value === 'strong' ? 8 : 4

      if (opt[value].index === 0) {
        arr.push({
          text: p.substr(lengthOfTag, endIndex.index - lengthOfTag),
          [value]: true,
        });
      } else {
        arr.push({ text: p.substr(index, opt[value].index) });
        arr.push({
          text: p.substr(opt[value].index + lengthOfTag + index, (endIndex.index - opt[value].index - lengthOfTag)),
          [value]: true,
        })
      }

      index += endIndex.index + (lengthOfTag + 1);
    } else {
      arr.push({ text: p.substr(index) });
      emExists = false;
    }
  }

  return arr;
}

// Helper functiont to replace \n with a <br /> HTML tag

const addLineBreaks = (str) => (
  str.split('\n').map((word, index) => {
    if (!word || word === '\r') { return <br key={`${index}word`} />}

    return <span key={`${index}word`}>{word}<br /></span>
  })
)

const pullFirstNameFromTitle = (str) => (
  str.split(' - ')[1].split(' ')[0].toUpperCase()
)

const capitalise = (str) => {
  return str && (str.charAt(0).toLocaleUpperCase() + str.slice(1).toLocaleLowerCase())
}

export {
  stripHTMLTags,
  addLineBreaks,
  pullFirstNameFromTitle,
  capitalise,
}

