import React, { useContext, useState } from 'react';

import CardWrapper from './CardWrapper';

import EpisodeContext from '../context/episodeContext';


const Episodes = ({ title, currentSelectedEpID }) => {
  const context = useContext(EpisodeContext);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState('');
  const [noSearchResults, setNoSearchResults] = useState(false);

  const handleChange = async (e) => {
    setSearch(e.target.value);

    if (!e.target.value) {
      setNoSearchResults(false)
      return setSearchResults('');
    }

    const temp = context.episodes.filter(ep => ep.title.toLocaleLowerCase().match(e.target.value.toLocaleLowerCase()));
    if (!temp || temp.length === 0) {
      setNoSearchResults(true)
      return setSearchResults('');
    }

    if (noSearchResults) { setNoSearchResults(false) }
    setSearchResults(temp)
  }

  return (
    <>
      <div className={'searchWrapper'}>
        <input
          type={'text'}
          placeholder={'Search for Episode'}
          value={search}
          onChange={handleChange}
        />
      </div>

      <CardWrapper
        type={'allEpisodes'}
        subHeadingText={title}
        episodes={searchResults || context.episodes}
        lazyLoad={true}
        noSearchResults={noSearchResults}
        currentSelectedEpID={currentSelectedEpID}
      />
    </>
  )
};

export default Episodes;
