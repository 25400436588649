import '../styles/Loading.css';

const Loading = () => {
  return (
    <div className={'loading-spinner-content-wrapper'}>
      <div className={'dot1'}> </div>
      <div className={'dot2'}></div>
      <div className={'dot3'}></div>
    </div>
  )
};

export default Loading;
