import { capitalise } from './textHelpers';

// An empty object makes it revert to the default
const metaTags = (key, variable) => {
  const meta = {
    episode: {
      pageTitle: variable && variable.title,
      pageHeading: variable && variable.title,
      pageDescription: 'Check out this episode of the Passion and Perspective Podcast!',
      pageImage: variable && variable.s3_image_link,
    },
    listen: {
      pageTitle: 'Listen | Passion and Perspective Podcast',
      pageHeading: 'Listen | Passion and Perspective Podcast',
      pageDescription: 'Browse all the episodes of the Passion and Perspective Podcast.',
    },
    
    article: {
      pageTitle: variable && capitalise(variable.title),
      pageHeading: variable && capitalise(variable.title),
      pageDescription: 'Check out this episode of the Passion and Perspective Podcast!',
      pageImage: variable && variable.image_link,
    },
    articles: {
      pageTitle: 'Articles | Passion and Perspective Podcast',
      pageHeading: 'Articles | Passion and Perspective Podcast',
      pageDescription: 'Browse, and be inspired by, all the articles on the Passion and Perspective Podcast.',
    },

    about: {
      pageTitle: 'About | Passion and Perspective Podcast',
      pageHeading: 'About | Passion and Perspective Podcast',
      pageDescription: 'Passion and Perspective channels the best of the human spirit by sharing real and powerful stories, designed to inspire.',
    },

    book: {
      pageTitle: 'Book | Passion and Perspective Podcast',
      pageHeading: 'Book | Passion and Perspective Podcast',
      pageDescription: 'Passion and Perspective host Jonathan Robinson-Lees is committed to inspiring the community. Jonathan challenges individuals to embrace their passion and shift their mindset through a powerful and meaningful talk, delivered to corporates, schools, universities and community groups.',
    },
    
    contact: {
      pageTitle: 'Contact | Passion and Perspective Podcast',
      pageHeading: 'Contact | Passion and Perspective Podcast',
      pageDescription: 'Get in touch with Passion and Perspective host, Jonathan Robinson-Lees, if you have any feedback.',
    },
  };

  return meta[key];
};

export { metaTags };
