import React, { useState } from 'react';
import jsonp from 'fetch-jsonp';
import { stringify } from 'query-string';
import Bugsnag from '@bugsnag/js'

import Button from './Button';
import '../styles/MailChimpForm.css'

const MailChimpForm = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const submit = async (e) => {
    e.preventDefault();

    if (email.match(/[^@\s]+@[^@\s]+\.[^@\s]+/)) {
      const params = stringify({ EMAIL: email, tags: '6459551' })
      const url = process.env.REACT_APP_MAILCHIMP_URL.replace('/post?', '/post-json?')

      setSuccess('Subscribing ... ')
      try {
        await jsonp(`${url}&${params}`, { params: 'c', timeout: 3000 })
      } catch (error) { }
      try {
        await fetch(`${process.env.REACT_APP_SEND_WELCOME_EMAIL}?email=${email}`)
      } catch (error) {
        Bugsnag.notify(new Error(`Could not send welcome email to new user with email address: ${email}. ERROR: ${error.message || error}`))
      }
      setSuccess('Success! You are now subscribed!')
      if (onClose) { setTimeout(() => onClose(), 1000) }

    } else {
      setError('Incorrect email. Please try again.')
    }
  }

  return (
    <div className={'mailchimp-form'}>
      {onClose && (<div onClick={onClose} className={'close-button'}>&times;</div>)}
      {onClose && !success && (
        <div className={'subscribe-info'}>
          <p>
            {'Want to stay up to date with all the latest - sign up below!'}
          </p>
        </div>
      )}

      {success ? (
        <div className={'success-message'}>{success}</div>
      ) : (
        <form onSubmit={submit}>
          {error && <div className={'error'}>{error}</div>}
    
          <label htmlFor={'email'}>{'Keep up to date: '}</label>
          <input
            type={'email'}
            placeholder={'Enter your email'}
            name={'email'}
            value={email}
            onChange={(e) => {
              setError(undefined)
              setEmail(e.target.value)
            }}
            title={'Invalid email address'}
          />
          <div className={'mailchimp-form-button-wrapper'}>
            <Button text={'SIGN UP'} onClick={submit} />
          </div>
        </form>
      )}
    </div>

  )
}

export default MailChimpForm;
