import React, { useEffect, useState, useContext }from 'react';
import { Link, useParams } from 'react-router-dom';

import AppBody from '../components/AppBody';
import SubHeading from '../components/text/SubHeading';

import { addLineBreaks, pullFirstNameFromTitle } from '../functions/textHelpers';
import { getRandom } from '../functions/getRandom';
import EpisodeContext from '../context/episodeContext';
import '../styles/Articles.css';

const Article = () => {
  const params = useParams();
  const context = useContext(EpisodeContext);
  const [article, setArticle] = useState(undefined);
  const [relatedEpisode, setRelatedEpisode] = useState(undefined);
  const [relatedArticles, setRelatedArticles] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [initalRenderComplete, setInitalRenderComplete] = useState(false);
  const [noArticleForSlug, setNoArticleForSlug] = useState(undefined);

  useEffect(() => {
    if (initalRenderComplete) { setLoading(true) }
    setInitalRenderComplete(true);
    setNoArticleForSlug(false);

    (async () => {
      try {
        const articleToShow = context.articles.find(a => a.url_slug === params.slug);
        setArticle(articleToShow);

        if (articleToShow.episode_id) {
          setRelatedEpisode(context.episodes.find(ep => ep.id === articleToShow.episode_id));
        }

        const resp = await fetch(`${context.url}/articles/${articleToShow.id}/get-similar`).then(r => r.json());
        if (resp && resp.length > 1) { setRelatedArticles(resp.filter(a => a.id !== articleToShow.id)) }
        else { setRelatedArticles(getRandom(context.articles.filter(a => a.id !== articleToShow.id), 4)) }

        setLoading(false)
      } catch (error) {
        console.log('Article.jsx::useEffect() Error - ', error);
        setNoArticleForSlug(true)
        setLoading(false)
      }
    })()
  }, [params.slug, context.articles, context.episodes, context.url]);

  return (
    <AppBody metaKey={'article'} variable={article}>
      {noArticleForSlug ? (
        <div className={'no-article-for-slug'}>
          <p>No article for that URL</p>
        </div>
      ) : (
        article && (
          <>
            <SubHeading text={''} />

            <div className={'verticalSections single-article-content'}>
              <div className={`full-article ${(loading) ? 'article-loading' : ''}`}>
                <p className={'largeHeading'}>
                  {article.title}
                </p>
                <p className={'mediumHeading grey-text'}>
                  {article.sub_heading}
                </p>

                {article.image_link ? (
                  <div className={'article-image-wrapper'}>
                    <img
                      className={'article-image'}
                      src={article.image_link}
                      alt={`Article: ${article.title}`}
                    />
                  </div>
                ) : (
                  <div className={'article-image'} />
                )}

                <p className={'article-content'}>
                  {addLineBreaks(article.content)}
                </p>

                {relatedEpisode && (
                  <p>{`LISTEN TO ${pullFirstNameFromTitle(relatedEpisode.title)}'${pullFirstNameFromTitle(relatedEpisode.title)[pullFirstNameFromTitle(relatedEpisode.title).length - 1] === 'S' ? '' : 'S'} `}
                    <Link className={'article-link-to-ep'} to={`/listen/${relatedEpisode.url_slug}`}>
                      {'INSPIRING STORY'}
                    </Link>
                  </p>
                )}

                <p className={'article-content'}>
                  The Passion and Perspective Podcast exists to inspire the local community by sharing incredible stories from the Blue Mountains and Penrith.
                </p>
              </div>

              <div className={'discover related-articles'}>
                <div className={'discover-wrapper'}>
                  <p className={'mediumHeading'}>
                    {'Related Articles'}
                  </p>

                  {!loading && relatedArticles && relatedArticles.map((a, i) => (
                    <div key={`related-article${i}`} className={'related-articles-wrapper article-loading'}>
                      <Link to={`/articles/${a.url_slug}`} className={'related-article'}>
                        <div className={'related-article-row'}>
                          {a.image_link && <img src={a.image_link} alt={'Related Article'} className={'related-article-image'} height={'100px'} />}
                          <p>{a.title}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )
      )}
    </AppBody>
  )
};

export default Article;
