import React from 'react';

import FacebookLogo from '../images/facebook-white-48-48.png';
import InstagramLogo from '../images/instagram-white-48-48.png';
import MailChimpForm from './MailChimpForm';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <div className={'footer'}>
      <div className={'footer-content'}>
        <div className={'form-wrapper'}>
          <div className={'form-padding'}></div>
          <MailChimpForm />
          <div className={'form-padding'}></div>
        </div>

        <div className='socials-copyright-wrapper'>
          <a
            href={'https://www.facebook.com/passionperspectivepodcast'}
            target={'_blank'}
            rel={'noreferrer'}
            className={'socials-wrapper'}>
            <img src={FacebookLogo} alt={'Facebook Logo - White'} />
          </a>
          <a
            href={'https://www.instagram.com/passionperspectivepodcast'}
            target={'_blank'}
            rel={'noreferrer'}
            className={'socials-wrapper'}>
            <img src={InstagramLogo} alt={'Instagram Logo - White'} />
          </a>
          <p className={'copyright-text'}>&copy; Sporting Chance Media, 2022</p>
        </div>

      </div>

    </div>
  )
};

export default Footer;
