import '../styles/Button.css';

const Button = ({ text, inverse, style, onClick }) => {
  const inverseStyle = {}
  if (inverse) {
    inverseStyle.color = '#fff';
    inverseStyle.backgroundColor = '#000';
  }

  return (
    <button onClick={onClick} style={{...inverseStyle, ...style}}>
      <h4>
        {text}
      </h4>
    </button>
  )
};

export default Button;
