import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';

import SubHeading from './text/SubHeading';
import Card from './Card';
import UpArrowWhite from '../images/up_arrow_white.png';

const CardWrapper = ({ subHeadingText, episodes, type, lazyLoad, noSearchResults, currentSelectedEpID }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    setVisible(scrolled > 600);
  };
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const remove = window.addEventListener('scroll', toggleVisible);

    return () => window.removeEventListener('scroll', toggleVisible);
  }, [])

  return (
    <>
      <SubHeading text={subHeadingText} />

      {visible ? (
        <div onClick={scrollToTop} className={'scroll-to-top'}>
          <img width={30} height={30} src={UpArrowWhite} alt={'Scroll to Top Arrow'} />
          <p className={'heading'}>TOP</p>
        </div>
      ) : null}

      <div className={`cardWrapper ${noSearchResults ? 'fullWidth' : ''}`}>
        {noSearchResults ? (
          <h4 className='noSearchResults'>
            {'NO SEARCH RESULTS'}
          </h4>
        ) : (
          episodes && episodes.map((e, i) => {
            if (!e.s3_image_link || e.id === currentSelectedEpID) {
              return (
                <React.Fragment key={`${type}${i}`} />
              )
            }

            return (
              lazyLoad ? (
                <LazyLoad
                  height={250}
                  throttle={200}
                  offset={150}
                  key={`${type}${i}`}
                  className={'individualCard'}
                  once>
                  <Card episode={e} last={i === episodes.length - 1} />
                </LazyLoad>
              ) : (
                <div key={`${type}${i}`} className={'individualCard'}>
                  <Card episode={e} last={i === episodes.length - 1} />
                </div>
              )
            )
          })
        )}
      </div>
    </>
  )
};

export default CardWrapper;
