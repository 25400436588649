import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import About from '../pages/About';
import ArticlesParent from '../pages/ArticlesParent';
import Article from '../pages/Article';
import Contact from '../pages/Contact';
import Home from '../pages/Home';
import Episodes from '../pages/Episodes';
import Episode from '../pages/Episode';
import Book from '../pages/Book';
import Loading from './Loading';

import TopNav from './TopNav';
import ScrollToTop from './ScrollToTop';
import EpisodeContext from '../context/episodeContext';
import '../styles/App.css';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()]
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const App = () => {
  const [loading, setLoading] = useState(true);
  const [episodes, setEpisodes] = useState(undefined);
  const [articles, setArticles] = useState(undefined);
  // const [podcast, setPodcast] = useState([]);
  const [url, setUrl] = useState(undefined);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 899);


  const updateSize = () => {
    setIsMobile(window.innerWidth <= 768);
    setIsTablet(window.innerWidth <= 899)
  }

  useEffect(() => {
    (async () => {
      window.addEventListener('resize', updateSize);
      const apiURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PP_SERVER_ENDPOINT_DEV : process.env.REACT_APP_PP_SERVER_ENDPOINT_PROD
      const resp = await fetch(`${apiURL}/home`).then(r => r.json());
      setEpisodes(resp.episodes);
      setArticles(resp.articles);

      // Not currently using the general podcast details
      // setPodcast(resp.podcast);

      setUrl(apiURL)
      setLoading(false);

      return () => window.removeEventListener('resize', updateSize);
    })()
  }, []);

  return (
    <ErrorBoundary>
      {loading ? (
        <Loading />
      ) : (
        <EpisodeContext.Provider
          value={{
            episodes: episodes,
            // podcast: podcast,
            isMobile: isMobile,
            isTablet: isTablet,
            url: url,
            articles: articles,
          }}>
          <Router>
            <ScrollToTop />

            <TopNav />

            <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/articles' element={<ArticlesParent />} />
              <Route path='/articles/:slug' element={<Article />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/listen' element={<Episodes />} />
              <Route path='/listen/:slug' element={<Episode />} />
              <Route path='/book-a-talk' element={<Book />} />
            </Routes>
          </Router>
        </EpisodeContext.Provider>
      )}
    </ErrorBoundary>
  );
}

export default App;
